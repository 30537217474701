import React, { useState } from 'react'
import { Footer } from '../Common/Footer'

import "./AboutUsPage.css";

import mayur_sir from './../../Assests/images/Mayur_Sir.avif'
// import mayur_sir_2 from './../../Assests/images/Mayur_Sir_2.avif'
import mv_logo from './../../Assests/images/mv_logo_no_bg.webp'
// import { TagsGroup } from './TagsGroup';

import certif1 from './../../Assests/images/certificate_01.avif';
import certif2 from './../../Assests/images/certificate_02.avif';
import certif3 from './../../Assests/images/certificate_03.avif';
import certif4 from './../../Assests/images/certificate_04.avif';
import certif5 from './../../Assests/images/certificate_05.avif';
import { Backdrop } from '@mui/material';

import ParticleBackground from '../ParticleBackground/ParticleBackground';
import HorizontalScroll from './HorizontalScroll';


var certificates = [
	{
		cert: certif3,
		alt: 'Heterogenous Parallel Programming Certificate',
		back_color: '#ffd700aa'
	},
	{
		cert: certif1,
		alt: 'OS Fundamentals Certificate',
		back_color: '#ffd700aa'
	},
	{
		cert: certif2,
		alt: 'Multi OS Installation Certificate',
		back_color: '#ffd700aa'
	},
	{
		cert: certif4,
		alt: 'Real Time Rendering  Certificate',
		back_color: '#ffd700aa'
	},
	{
		cert: certif5,
		alt: 'Degree Certificate',
		back_color: '#0ffffa'
	},
];

export const AboutUsPage = () => {

	const [open, setOpen] = useState(false);
	const [certificateIndex, setCertificateIndex] = useState(0);

	const handleClose = () => {
		setOpen(false);
	};
	const handleOpen = () => {
		setOpen(true);
	};

	return (
		<div
			className='about-us-page'>

			<div
				style={{
					width: '100%',
					height: '100%',
					zIndex: '-1'
				}}>
				<ParticleBackground />
			</div>

			<div
				className='content-row'
				style={{
					background: 'transparent'
				}}>
				<h1
					className='sgmp'>
					|| श्री गोखले मास्तर प्रसन्न ||
				</h1>
			</div>

			{/* <div
				className='content-row row-1'
				style={{
					background: 'transparent',
					// "linear-gradient(to top left, rgba(32, 32, 32, 0.5), rgba(0, 155, 155, 0.3) 100%)",
				}}>
				<div
					className='content-group'>
					<div
						className='content-group-image'>
						<img
							className='sirs-image'
							src={mayur_sir}
							alt='Tadekar Mayur Sir'
						/>
						<h1
							className='sirs-name'>
							Mayur मास्तर (Sir)
						</h1>
					</div>

					<TagsGroup
						tags={tags}
					/>
				</div>
			</div> */}

			<div
				className='content-row row-2 '
				style={{
					background: 'transparent',
				}}
			>
				<div
					className='content-group'>

					<div
						className='content-group-image'>
						<img
							className='sirs-image'
							src={mayur_sir}
							alt='Tadekar Mayur Sir'
						/>
					</div>

					<div
						className='content-group-sir-info'>

						<h1
							className='sirs-name-2'>
							Tadekar Mayur
						</h1>
						<p>
							Mayur मास्तर (Sir) is in the true sense a beacon of inspiration, dedication, discipline, consistency, and hard work for students. Sir completed his Bachelor's in Computer Engineering from Pune University. During his time in engineering, Sir got a year down, and in his words,
							"कॉलेज मध्ये मी एकटा YD घेतल्या मुळे आनंदी होतो". <strong>Dr. Vijay D Gokhale Sir (मोठे मास्तर)</strong> is his Guru, from whom Sir gained knowledge of UNIX OS Internals and Design, WIN32 SDK, COM (Component Object Model), WinRT, OpenGL, and DirectX. Based on all his knowledge, Sir created his own identity as a hardcore coder from a very young age.
						</p>

						<p>
							Mayur Sir started teaching from his college days, covering small concepts of DSA to the complete C programming language for his friends, teaching them on the college lawn and in his hostel room. During the lockdown, as offline teaching stopped, his friends would get on conference calls with him, and Sir taught them in this way too, which Sir calls LOC (Lecture On Calls).
						</p>

						<p>
							On <strong>24th June 2020</strong>, Sir established Marshalling Void during his final year of engineering, where Sir teaches x86 Linux Assembly, C, C++, Java, Python, DSA, and Web Development. Sir believes in three golden words, <strong>"करायचं चुकायचं शिकायचं" (To Do, To Err, To Learn)</strong>, and therefore focuses on hands-on learning and project building, teaching the Why, What, and How of every concept. With real-time and real-life example mapping to every concept, Sir makes everything very simple to understand and implement.
						</p>

						<p>
							Sir keeps himself updated with every new and emerging technology so that Sir can share it with all of us. Sir is always ready to help us with our doubts on anything, anytime, and anywhere. With his exceptional skillset, Sir is always an inspiration to all of us students.
						</p>


					</div>

				</div>
			</div>

			<h2
				className='field_of_work  block'>
				Sir's Field of Work
			</h2>

			<HorizontalScroll />

			<div
				className='content-row row-3 block'
				style={{
					background: 'transparent',
				}}>

				<h1
					className='certificate-group-title'>
					Certifications
				</h1>

				<div
					className='certificate-group'>
					{
						certificates.map((certificate, index) => {
							return (
								<div
									className='certificate-group-image'
									onClick={() => {
										setCertificateIndex(index)
										handleOpen()
									}}>
									<img
										className='certificate-image'
										style={{
											boxShadow: '0px 0px 30px ' + certificate.back_color,
										}}
										src={certificate.cert}
										alt={certificate.alt}
									/>
								</div>
							);
						})
					}
				</div>
			</div>

			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={open}
				onClick={handleClose}
			>
				<img
					style={{
						width: certificateIndex < 3 ? '70%' : '',
						height: certificateIndex < 3 ? '' : '70%',
					}}
					src={certificates[certificateIndex].cert}
					alt={certificates[certificateIndex].alt}
				/>
			</Backdrop>

			<div
				className='content-row row-2 block'
				style={{
					background: 'transparent',
				}}
			>
				<div
					className='content-group row-4'>

					<div
						className='content-group-image'>
						<img
							className='mv-logo'
							src={mv_logo}
							alt='Marshalling Void Logo'
						/>
					</div>

					<div
						className='content-group-class-info'>

						<h1
							className='sirs-name-2'>
							Marshalling Void
						</h1>
						<p>
							<strong>Marshalling Void</strong> is a premier IT training institute based in Nashik, India, founded by Tadekar Mayur Sir in 2020 during the COVID-19 lockdown. Initially an online-only venture, the institute transitioned to an offline format in 2024 to enhance accessibility for students.
							Since its inception, <strong>Marshalling Void</strong> has empowered over 1,000 students to build successful careers in the IT sector.
						</p>
						<p>
							Each course at <strong>Marshalling Void</strong> is meticulously designed to provide comprehensive hands-on training. Students gain deep insights into core concepts, which they then apply to multiple real-world projects. This practical approach ensures that our graduates are well-equipped to tackle challenges in the tech industry.
						</p>
						<p>
							Our mission at <strong>Marshalling Void</strong> is to transform students into exceptional software developers capable of excelling in any domain and securing positions in their dream companies. We are dedicated to shaping the future of our students, which in turn contributes to the future of our nation.
						</p>
					</div>
				</div>
			</div>

			<Footer />
		</div >
	)
}
